import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SEO, Archive } from '@components'
import './work.scss'

const WorkPage = () => {
  const [email] = useState('hey@bradzickafoose.com')
  const [showToast, setShowToast] = useState(false)
  const [toastText, setToastText] = useState('')

  const copyText = value => {
    window.navigator.clipboard.writeText(value)
    setShowToast(true)
    setToastText(`${value} copied to the clipboard.`)
  }

  useEffect(() => {
    if (showToast) {
      setTimeout(() => setShowToast(false), 2500)
    }
  }, [showToast, setShowToast])

  const data = useStaticQuery(graphql`
    query imageQuery {
      imageCY: file(relativePath: { eq: "images/project-cy.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageOPT: file(relativePath: { eq: "images/project-opt.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Recent work" />
      <h1 className="txt-center">At Telzio, working as a Senior&nbsp;Frontend Developer.</h1>
      <p className="txt-center txt-heavy push-medium--bottom">
        Full-stack developer and designer in Orlando, Florida.
      </p>
      <p className="txt-center push-large--bottom">
        <button
          className="txt-medium button"
          style={{ outline: 'none' }}
          onClick={() => copyText(email)}
        >
          {email}
        </button>
      </p>
      <section className="work-box work-box--seabreeze grid__wide push-x-large--bottom">
        <div className="work-box__copy txt-center-mobile">
          <h2 className="flush--top">Courtyard by Marriott</h2>
          <p>
            UI design and web development for rapidly creating independent
            Marriott hotel websites.
          </p>
          <a
            href="https://marriott.bradzickafoose.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View project
          </a>
          &nbsp;→
        </div>
        <a
          href="https://marriott.bradzickafoose.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            className="work-box__image"
            fluid={data.imageCY.sharp.fluid}
            alt="Banner of the Courtyard by Marriott website, featuring a desktop and mobile view"
            fadeIn
            loading="lazy"
          />
        </a>
      </section>
      <section className="work-box work-box--reverse work-box--orangepeel grid__wide push-x-large--bottom">
        <div className="work-box__copy txt-center-mobile">
          <h2 className="flush--top">Orange Peel Transportation</h2>
          <p>
            A bold new design and reimagined booking platform. I was responsible
            for everything including rebranding visual identity, UI design,
            WordPress development, analytics, and optimization.
          </p>
          <a
            href="https://www.orangepeeltransportation.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            View project
          </a>
          &nbsp;→
        </div>
        <a
          href="https://www.orangepeeltransportation.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            className="work-box__image"
            fluid={data.imageOPT.sharp.fluid}
            alt="Banner of OrangePeelTransportation.com, featuring a desktop and mobile view"
            fadeIn
            loading="lazy"
          />
        </a>
      </section>
      <section className="grid__wide push-large--bottom">
        <Archive />
      </section>
      <section className="txt-center flush--bottom">
        <a href="https://www.github.com/bradzickafoose">
          View more projects on GitHub
        </a>
        &nbsp;→
      </section>
      {showToast && (
        <div className="toast">
          <div className="toast-body">{toastText}</div>
        </div>
      )}
    </Layout>
  )
}

export default WorkPage
